document.addEventListener( "DOMContentLoaded", function () {
    var menuButton = document.querySelector( '.custom-header__link--menu' );
    var navContainer = document.querySelector( '.custom-header__nav-container' );
    var body = document.body;

    menuButton.addEventListener( 'click', function () {
        if ( !navContainer.classList.contains( 'is-visible' ) ) {
            navContainer.classList.add( 'is-visible' );
            menuButton.textContent = 'Close';
            body.classList.add( 'has-menu-overlay' );
        } else {
            navContainer.classList.remove( 'is-visible' );
            menuButton.textContent = 'Menu';
            body.classList.remove( 'has-menu-overlay' );
        }
    } );
} );

document.addEventListener( "scroll", function () {
    var header = document.querySelector( '.custom-header' );

    if ( window.scrollY > 200 ) {
        header.classList.add( 'is-fixed' );
    } else {
        header.classList.remove( 'is-fixed' );
    }
} );

document.addEventListener( 'DOMContentLoaded', () => {
    function shareContent( element ) {
        if ( navigator.share ) {
            const title = element.getAttribute( 'data-title' );
            const text = element.getAttribute( 'data-text' );
            const url = element.getAttribute( 'data-url' );
            navigator.share( {
                title: title,
                text: text,
                url: url,
            } )
                .then( () => console.log( 'Successfully shared' ) )
                .catch( ( error ) => console.log( 'Error sharing', error ) );
        }
    }

    const shareLink = document.querySelector( '.sb-journal-details__link--share' );
    if ( shareLink ) {
        if (!navigator.share) {
            shareLink.style.display = 'none';
        }

        shareLink.addEventListener( 'click', ( event ) => {
            event.preventDefault();
            shareContent( event.target );
        } );
    }
});

/**
 * Enhances the accessibility of reCAPTCHA elements by adding appropriate ARIA labels.
 * 
 * This function uses a MutationObserver to detect when reCAPTCHA elements are added to the DOM
 * and applies proper ARIA attributes to improve accessibility for screen readers.
 * 
 * The following ARIA attributes are added:
 * - `aria-label="reCAPTCHA verification"` to the reCAPTCHA iframe
 * - `aria-label="reCAPTCHA challenge"` to any reCAPTCHA challenge iframe that appears
 * - `aria-label="reCAPTCHA response"` to the hidden textarea that holds the response
 * - `aria-label="reCAPTCHA verification widget"` to the badge container
 */
function enhanceRecaptchaA11y() {
    const observer = new MutationObserver((mutations) => {
        const recaptchaBadge = document.querySelector('.grecaptcha-badge');
        if (recaptchaBadge) {
            recaptchaBadge.setAttribute('aria-label', 'reCAPTCHA verification widget');
            const iframe = recaptchaBadge.querySelector('iframe');
            if (iframe && !iframe.hasAttribute('aria-label')) {
                iframe.setAttribute('aria-label', 'reCAPTCHA verification');
                iframe.setAttribute('title', 'reCAPTCHA');
            }
            const responses = document.querySelectorAll('textarea[name="g-recaptcha-response"]');
            responses.forEach(response => {
                if (!response.hasAttribute('aria-label')) {
                    response.setAttribute('aria-label', 'reCAPTCHA response');
                }
            });
        }
        const challengeIframes = document.querySelectorAll('iframe[title="recaptcha challenge"]');
        challengeIframes.forEach(iframe => {
            if (!iframe.hasAttribute('aria-label')) {
                iframe.setAttribute('aria-label', 'reCAPTCHA challenge');
            }
        });
    });
    observer.observe(document.body, {
        childList: true,
        subtree: true,
        attributes: true,
        attributeFilter: ['class', 'style']
    });
    const recaptchaBadge = document.querySelector('.grecaptcha-badge');
    if (recaptchaBadge) {
        const iframe = recaptchaBadge.querySelector('iframe');
        if (iframe) {
            iframe.setAttribute('aria-label', 'reCAPTCHA verification');
            iframe.setAttribute('title', 'reCAPTCHA');
        }
        recaptchaBadge.setAttribute('aria-label', 'reCAPTCHA verification widget');
    }
    const responses = document.querySelectorAll('textarea[name="g-recaptcha-response"]');
    responses.forEach(response => {
        response.setAttribute('aria-label', 'reCAPTCHA response');
    });
}

/**
 * Enhances the accessibility of the Google Translate widget by adding appropriate ARIA roles and labels.
 * 
 * This function uses a MutationObserver to monitor changes in the DOM and applies ARIA attributes to various
 * elements within the Google Translate widget to improve accessibility for screen readers.
 * 
 * The following ARIA attributes are added:
 * - `role="complementary"` and `aria-label="Google Translate Widget"` to the main widget container.
 * - `role="region"` and `aria-label="Original text section"` to the original text container.
 * - `role="region"` and `aria-label="Translation feedback section"` to the feedback section.
 * - `aria-label="Rate as good translation"` and `role="button"` to the thumbs up button.
 * - `aria-label="Rate as poor translation"` and `role="button"` to the thumbs down button.
 * - `aria-label="Translation feedback form"` to the voting form.
 * - `aria-label` attributes to the input fields within the voting form based on their IDs.
 * - `aria-label="Google Translate Logo"` and `role="img"` to the Google Translate logo.
 * 
 * The function disconnects the observer once the widget is found and enhanced.
 */
function enhanceGoogleTranslateA11y() {
    const observer = new MutationObserver((mutations, obs) => {
        const translateWidget = document.getElementById('goog-gt-tt');
        if (translateWidget) {
            obs.disconnect();

            translateWidget.setAttribute('role', 'complementary');
            translateWidget.setAttribute('aria-label', 'Google Translate Widget');

            const originalTextContainer = document.querySelector('.VIpgJd-yAWNEb-hvhgNd-k77Iif-i3jM8c');
            if (originalTextContainer) {
                originalTextContainer.setAttribute('role', 'region');
                originalTextContainer.setAttribute('aria-label', 'Original text section');
            }

            const feedbackSection = document.querySelector('.VIpgJd-yAWNEb-hvhgNd-N7Eqid');
            if (feedbackSection) {
                feedbackSection.setAttribute('role', 'region');
                feedbackSection.setAttribute('aria-label', 'Translation feedback section');
            }

            const thumbUpButton = document.getElementById('goog-gt-thumbUpButton');
            const thumbDownButton = document.getElementById('goog-gt-thumbDownButton');

            if (thumbUpButton) {
                thumbUpButton.setAttribute('aria-label', 'Rate as good translation');
                thumbUpButton.setAttribute('role', 'button');
            }

            if (thumbDownButton) {
                thumbDownButton.setAttribute('aria-label', 'Rate as poor translation');
                thumbDownButton.setAttribute('role', 'button');
            }

            const votingForm = document.getElementById('goog-gt-votingForm');
            if (votingForm) {
                votingForm.setAttribute('aria-label', 'Translation feedback form');

                const formInputs = votingForm.querySelectorAll('input[type="text"]');
                formInputs.forEach(input => {
                    const inputId = input.id;
                    const labelText = {
                        'goog-gt-votingInputSrcLang': 'Source language',
                        'goog-gt-votingInputTrgLang': 'Target language',
                        'goog-gt-votingInputSrcText': 'Original text',
                        'goog-gt-votingInputTrgText': 'Translated text',
                        'goog-gt-votingInputVote': 'Vote value'
                    }[inputId];

                    if (labelText) {
                        input.setAttribute('aria-label', labelText);
                    }
                });
            }

            const translateLogo = document.querySelector('.VIpgJd-yAWNEb-hvhgNd-l4eHX-i3jM8c img');
            if (translateLogo) {
                translateLogo.setAttribute('aria-label', 'Google Translate Logo');
                translateLogo.setAttribute('role', 'img');
            }
        }
    });

    observer.observe(document.body, {
        childList: true,
        subtree: true
    });
}

/**
 * Enhances the accessibility (a11y) of Contact Form 7 forms on the page.
 * 
 * This function configures the accessibility features for two specific forms:
 * - Signup form
 * - Membership form
 * 
 * It sets appropriate ARIA attributes to improve the accessibility of the forms,
 * including labels, descriptions, and live regions for dynamic content.
 * 
 * The function also observes changes to the response output elements to ensure
 * that ARIA attributes are updated accordingly.
 * 
 * The forms are enhanced when the DOM content is fully loaded.
 * 
 * @function enhanceCF7FormsA11y
 */
function enhanceCF7FormsA11y() {
    const formConfigs = {
        signup: {
            container: '.sb-sign-up__container',
            title: '.sb-sign-up__title',
            info: '.sb-sign-up__info',
            form: '#revinate_contact_api_form'
        },
        membership: {
            container: '.sb-membership',
            title: '.sb-membership__title',
            info: '.sb-membership__info',
            form: '#wpcf7-f1713-p367-o1 form'
        }
    };

    function enhanceForm(formElement, config) {
        if (!formElement) return;

        const container = formElement.closest(config.container);
        const title = container?.querySelector(config.title);
        const info = container?.querySelector(config.info);
        if (title) {
            const titleId = `form-title-${formElement.id}`;
            title.id = titleId;
            formElement.setAttribute('aria-labelledby', titleId);
        }
        if (info) {
            const infoId = `form-info-${formElement.id}`;
            info.id = infoId;
            info.setAttribute('role', 'note');
            formElement.setAttribute('aria-describedby', infoId);
        }
        formElement.querySelectorAll('input[type="text"], input[type="email"]').forEach(input => {
            const wrapper = input.closest('.wpcf7-form-control-wrap');
            if (wrapper) {
                const fieldName = wrapper.getAttribute('data-name');
                if (fieldName) {
                    input.setAttribute('aria-label', input.getAttribute('placeholder') || fieldName);
                }
            }
        });
        const submitButton = formElement.querySelector('input[type="submit"]');
        if (submitButton) {
            submitButton.setAttribute('aria-label', 'Submit form');
        }
        const responseOutput = formElement.querySelector('.wpcf7-response-output');
        if (responseOutput) {
            responseOutput.setAttribute('role', 'alert');
            responseOutput.setAttribute('aria-live', 'polite');
        }
        const screenReaderResponse = formElement.closest('.wpcf7')?.querySelector('.screen-reader-response');
        if (screenReaderResponse) {
            screenReaderResponse.setAttribute('role', 'alert');
            const statusElement = screenReaderResponse.querySelector('[role="status"]');
            if (statusElement) {
                statusElement.setAttribute('aria-live', 'polite');
            }
        }
        const spinner = formElement.querySelector('.wpcf7-spinner');
        if (spinner) {
            spinner.setAttribute('role', 'status');
            spinner.setAttribute('aria-label', 'Loading');
            spinner.setAttribute('aria-hidden', 'true');
        }
    }

    function initializeForms() {
        const signupForm = document.querySelector(formConfigs.signup.form);
        if (signupForm) {
            enhanceForm(signupForm, formConfigs.signup);
        }

        const membershipForm = document.querySelector(formConfigs.membership.form);
        if (membershipForm) {
            enhanceForm(membershipForm, formConfigs.membership);
        }
    }

    if (document.readyState === 'loading') {
        document.addEventListener('DOMContentLoaded', initializeForms);
    } else {
        initializeForms();
    }
    const statusObserver = new MutationObserver(mutations => {
        mutations.forEach(mutation => {
            if (mutation.target.classList.contains('wpcf7-response-output')) {
                mutation.target.setAttribute('aria-hidden', 'false');
            }
        });
    });

    document.querySelectorAll('.wpcf7-response-output').forEach(output => {
        statusObserver.observe(output, {
            attributes: true,
            attributeFilter: ['style', 'class']
        });
    });
}



/**
 * Ensures the Google Translator dropdown "Select Language" label always shows in English.
 * 
 * This function finds all Google Translator dropdown elements on the page and sets
 * the text of the first option (default option) to "Select Language" in English,
 * regardless of the user's browser language settings.
 * 
 */
function fixGoogleTranslatorLabel() {
    (function () {
        function setEnglishLabel() {
            var selects = document.querySelectorAll('.goog-te-combo');
            if (selects.length > 0) {
                for (var i = 0; i < selects.length; i++) {
                    if (selects[i].options.length > 0) {
                        selects[i].options[0].text = 'Select Language';
                    }
                }
            }
        }

        if (document.readyState === 'loading') {
            document.addEventListener('DOMContentLoaded', function () {
                setEnglishLabel();
                setTimeout(setEnglishLabel, 1000);
            });
        } else {
            setEnglishLabel();
            setTimeout(setEnglishLabel, 1000);
        }

        setTimeout(setEnglishLabel, 3000);
    })();
}


document.addEventListener('DOMContentLoaded', enhanceRecaptchaA11y);
window.addEventListener('load', enhanceRecaptchaA11y);

document.addEventListener('DOMContentLoaded', enhanceGoogleTranslateA11y);
document.addEventListener('DOMContentLoaded', enhanceCF7FormsA11y);

window.addEventListener('load', enhanceGoogleTranslateA11y);
window.addEventListener('load', enhanceCF7FormsA11y);

document.addEventListener('DOMContentLoaded', fixGoogleTranslatorLabel);
window.addEventListener('load', fixGoogleTranslatorLabel);


document.addEventListener('DOMContentLoaded', function() {
    function scrollToElement(targetKey) {
        const targetElement = document.querySelector(`[data-anchor="${targetKey}"]`);
        if (targetElement) {
            const offset = 100;
            const elementPosition = targetElement.getBoundingClientRect().top + window.pageYOffset;
            const offsetPosition = elementPosition - offset;

            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth'
            });
        }
    }

    if (window.location.hash) {
        const targetKey = window.location.hash.substring(1);
        scrollToElement(targetKey);
    }

    const scrollLinks = document.querySelectorAll('.scroll-link');
    scrollLinks.forEach(link => {
        link.addEventListener('click', function(event) {
            event.preventDefault();
            const targetKey = this.getAttribute('href').split('#')[1];
            const targetPage = this.getAttribute('href').split('#')[0];
            const currentPage = window.location.pathname;

            if(targetPage === "" || targetPage.endsWith(currentPage)){
                scrollToElement(targetKey);
            } else {
                window.location.href = this.getAttribute('href');
            }
        });
    });
});



